import ClipCraftThumbnail from "../assets/projects/Clip_Craft_Thumbnail.svg";
import NimbusThumbnail from "../assets/projects/Nimbus_Weather_App.svg";
import QuizzeyThumbnail from "../assets/projects/Quizzey_Logo.png";
import ApoThumbnail from "../assets/projects/Apo_Logo.png";

export const projectList = [
	{
		name: "Apo Suite", 
		image: ApoThumbnail, 
		imageAlt: "Apo Suite Logo", 
		githubLink: "https://apo-suite.com/", 
		skills: "", 
		enabled: true, 
		featued: true
	}, 
	{
		name: "Nimbus - Weather App", 
		image: NimbusThumbnail, 
		imageAlt: "", 
		githubLink: "https://github.com/sam-burford/Nimbus_Weather_App", 
		skills: "", 
		enabled: true
	}, 
	{
		name: "Quizzey", 
		image: QuizzeyThumbnail, 
		imageAlt: "Pharmacy Hub - written in a clean and elegant font", 
		githubLink: "https://github.com/sam-burford/Quizzey", 
		skills: "stuff", 
		enabled: true, 
		featued: true
	}, 
	// {
	// 	name: "ClipCraft - Video Editor", 
	// 	image: ClipCraftThumbnail, 
	// 	imageAlt: "Clip Craft - Video Editor Logo with a video camera", 
	// 	githubLink: "", 
	// 	skills: "React.js", 
	// 	enabled: false
	// }, 
];