import React from 'react'
/* Styles */
import "../styles/Home.css";
/* Components */
import SocialLinks from '../components/SocialLinks';
import WhoIAmText from '../components/WhoIAmText';
import NextPageBanner from '../components/NextPageBanner';
/* Images */
import PortraitImage from "../assets/home/portrait.png";

function Home() {
  return (
	<div className='home'>
    <div className='about'>
      <div id='portraitBorder'>
        <img id='portraitImg' src={PortraitImage} alt='Portrait on Sam Burford looking into the distance' />
      </div>
      <div className='prompt'>
        <h1>Hi, I'm <span id='promptSpecialText'>Sam</span>,</h1>
        <h1><WhoIAmText /></h1>
        <SocialLinks />
      </div>
    </div>
    <div className='skills'>
      <h1>Skills</h1>
      <ol className='list'>
        <li className='item'>
          <h2>Front-End</h2>
          <span>
            Angular.js, React.js, HTML, CSS, NPM, Yarn, 
            Bootstrap, MaterialUI, jQuery
          </span>
        </li>
        <li className='item'>
          <h2>Back-End</h2>
          <span>
            ASP.NET, Azure, Node.js, Express.js, MySQL, AWS
          </span>
        </li>
        <li className='item'>
          <h2>Languages</h2>
          <span>
            JavaScript, TypeScript, C#, Java, C++, Lua
          </span>
        </li>
      </ol>
    </div>
    <NextPageBanner to="/about" />
  </div>
  )
}

export default Home